import React from 'react';
import './slide5.css';

const Slide5 = () => {

    return (
        <div class={"animate__animated animate__fadeInDown Outer-Container"}>
            <h1>Virtual DOM</h1>
            <div class={"Inner-Container5"}>
                <div  class={"Left-Container"}>
                    <h2 className={"VDOM-header"}>DOM (Document Object Model) konstruiert die Seite aus HTML-Code</h2>
                    <ul>
                        <li className={"VDOM-List-Element"}>Aktualisiert bei jeder Änderung die Seite neu</li>
                    </ul>
                </div>
                <div class={"Line"}/>
                <div  class={"Right-Container"}>
                    <h2 className={"VDOM-header"}>Virtual DOM ist eine virtuelle Repräsentation im Speicher des DOMs</h2>
                    <ul>
                        <li className={"VDOM-List-Element"}>Vergleicht zuerst welche Komponenten geändert wurden</li>
                        <li className={"VDOM-List-Element"}>Aktualisiert nur die geänderten Teile der Seite</li>
                    </ul>
                </div>
            </div>
        </div>
    )

}

export default Slide5;