import "./bulletPoint.css"

const BulletPoint = ({TextString, PointPos}) => {

    let flexDirectionValue;
    let marginValue;

    switch (PointPos) {
        case 0:
            flexDirectionValue = "column";
            marginValue ="10px 0 0 0";
            break;
        case 1:
            flexDirectionValue = "row-reverse";
            marginValue = "0 10px 0 0";
            break;
        case 2:
            flexDirectionValue = "column-reverse";
            marginValue = "0 0 10px 0";
            break;
        case 3:
            flexDirectionValue = "row";
            marginValue = "0 0 0 10px";
            break;
        default:
            flexDirectionValue = "column";
            marginValue = "10px 0 0 0";
    }


    return (
        <div className={"BulletPoint-Container"} style={{ flexDirection: flexDirectionValue}}>
            <div className={"BulletPoint"}>&nbsp;</div>
            <p className={"BulletPointText"}>{TextString}</p>
        </div>
    )

}

export default BulletPoint;