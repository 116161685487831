import './frageSlide.css';

const FrageSlide = ({UpperString, LowerString}) => {

    return (
        <div class={"animate__animated animate__fadeInDown Outer-Container Frage-Container"}>
            <h1>{UpperString}</h1>
            <h2>{LowerString}</h2>
        </div>
    )
}

export default FrageSlide;