import '../slide5/slide5.css';
const Slide17 = () => {

    return (
        <div class={"animate__animated animate__fadeInDown Outer-Container"}>
            <h1>Datenfluss</h1>
            <div class={"Inner-Container10"}>
                <h2>Wie ist der Datenfluss?</h2>
                <ul>
                    <li>Unidirektional</li>
                    <li>Daten können von Parent-Komponenten zu Child-Komponenten übergeben werden</li>
                    <li>Child-Komponente können nicht Daten an Parent-Komponente übergeben</li>
                </ul>
                <div>
                    <h2><u>useContext</u></h2>
                    <h4 class={"State-Text"}>Daten an Komponenten geben, wenn sie von der Provider-Komponete umgeben sind</h4>
                </div>
            </div>
        </div>
    )

}

export default Slide17;