import './slide7.css';

const Slide7 = () => {

    return (
        <div class={"animate__animated animate__fadeInDown Outer-Container"}>
            <div class={"JSX-Container"}>
                <h1>JSX</h1>
                <h5>(JavaScript Syntax Extension/JavaScript XML)</h5>
            </div>
            <div class={"Inner-Container10"}>
                <h2 class={"JSX-h2"}>Was ist JSX?</h2>
                <ul>
                    <li>Eine Syntax-Erweiterung in JavaScript</li>
                    <li>HTML/XML kann direkt programmiert werden</li>
                    <li>Alles auch mit TypeScript (TSX)</li>
                </ul>
            </div>
        </div>
    )

}

export default Slide7;