import './slide10.css';

const Slide10 = () => {

    return (
        <div class={"animate__animated animate__fadeInDown Outer-Container"}>
        <h1>Komponenten</h1>
            <div class={"Inner-Container10"}>
                <div>
                    <h2>Was sind Komponenten?</h2>
                    <ul>
                        <li>Komponenten sind ein Baustein für die GUI, die aus JSX-Code besteht</li>
                        <li>Jede Komponente hat eigene Zustände und eine render-Methode</li>
                    </ul>
                </div>
                <div>
                    <h2>Vorteile</h2>
                    <ul>
                        <li>Wiederverwendbarkeit</li>
                        <li>Modularität</li>
                        <li>Bessere Lesbarkeit</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Slide10;