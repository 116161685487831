import BulletPoint from "../bulltPoints/bulletPoint.componnent";
import "./slide3.css"

const Slide3 = () => {


    return (
        <div>
            <h1 className={"HistoryHeader"}>Geschichte von React</h1>
            <div className={"Most-Outer-BulletPoint-Container HistoryContainer"}>
                <div className={"animate__animated animate__fadeInDown Outer-BulletPoint-Container"}>
                    <BulletPoint TextString={"2011: Jordan Walke entwickelt React bei Facebook"} PointPos={3}></BulletPoint>
                    <BulletPoint TextString={"2012: React wird für  Instagram eingesetzt"} PointPos={3} ></BulletPoint>
                    <BulletPoint TextString={"2013: React wird Open-Source"} PointPos={3}></BulletPoint>
                    <BulletPoint TextString={"2015: React-Native (React für mobile Geräte) wird veröffentlicht"} PointPos={3}></BulletPoint>
                    <BulletPoint TextString={"2018: Hooks werden eingeführt"} PointPos={3}></BulletPoint>
                    <BulletPoint TextString={"2022: React v18.0 (neueste Version) wird veröffentlicht"} PointPos={3}></BulletPoint>
                    <BulletPoint TextString={"2023: react.dev wird als neue Webseite verwendet"} PointPos={3}></BulletPoint>
                </div>
                <div className={"Big-Line"}>&nbsp;</div>
            </div>
        </div>
    );

}

export default Slide3;