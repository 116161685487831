import "./slide1.css"
import BulletPoint from "../bulltPoints/bulletPoint.componnent";

const Slide1 = () => {

    return (
        <div className={"Most-Outer-BulletPoint-Container"}>
            <div className={"animate__animated animate__fadeInDown Outer-BulletPoint-Container"}>
                <BulletPoint TextString={"Was ist React?"} PointPos={3}></BulletPoint>
                <BulletPoint TextString={"Geschichte von React"} PointPos={3} ></BulletPoint>
                <BulletPoint TextString={"Wie erstelle ich eine React-App?"} PointPos={3}></BulletPoint>
                <BulletPoint TextString={"Virtual DOM"} PointPos={3}></BulletPoint>
                <BulletPoint TextString={"JSX"} PointPos={3}></BulletPoint>
                <BulletPoint TextString={"Komponenten"} PointPos={3}></BulletPoint>
                <BulletPoint TextString={"Hooks"} PointPos={3}></BulletPoint>
                <BulletPoint TextString={"Datenfluss"} PointPos={3}></BulletPoint>
                <BulletPoint TextString={"Built-In API's"} PointPos={3}></BulletPoint>
                <BulletPoint TextString={"Quellen"} PointPos={3}></BulletPoint>
            </div>
            <div className={"Big-Line"}>&nbsp;</div>
        </div>
    );
}

export default Slide1;