import './App.css';
import Slide0 from "./slides/slide0/slide0.component";
import {useEffect, useState} from "react";
import Slide2 from "./slides/slide2/slide2.component";
import Slide1 from "./slides/slide1/slide1.component";
import Slide3 from "./slides/slide3/slide3.component";
import Slide4 from "./slides/slide4/slide4.component";
import Slide5 from "./slides/slide5/slide5.component";
import Slide7 from "./slides/slide7/slide7.component";
import Slide8 from "./slides/slide8/slide8.component";
import Slide10 from "./slides/slide10/slide10.component";
import Slide11 from "./slides/slide11/slide11.component";
import BeispielSlide from "./slides/BeispielSlide/beispielslide.component";
import FrageSlide from "./slides/FrageSlide/frageSlide.component";
import Slide14 from "./slides/slide14/slide14.component";
import Slide17 from "./slides/slide17/slide17.component";
import Slide20 from "./slides/slide20/slide20.component";
import Slide22 from "./slides/slide22/slide22.component";
import Slide23 from "./slides/slide23/slide23.component";

function App() {

    const [count, setCount] = useState(0);
    const minLength = 0;
    const maxLength = 23;

    useEffect(() => {
        const handleKeyDown = (event) => {

            if (event.key === "ArrowRight") {
                incCount();
            }

            if (event.key === "ArrowLeft") {
                decCount();
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    });

    function incCount() {
        setCount((prevCount) => {
            const newCount = prevCount + 1;
            return newCount >  maxLength ? maxLength : newCount;
        });
    }

    function decCount() {
        setCount((prevCount) => {
            const newCount = prevCount - 1;
            return newCount < minLength ? minLength : newCount;
        });
    }

  return (
      <div class={"App"}>
          <div class={"App-header"}>
              {count === 0 ? <Slide0/> : null}
              {count === 1 ? <Slide1/> : null}
              {count === 2 ? <Slide2/> : null}
              {count === 3 ? <Slide3/> : null}
              {count === 4 ? <Slide4/> : null}
              {count === 5 ? <Slide5/> : null}
              {count === 6 ? <FrageSlide UpperString={"Wie programmiert man in React?"} LowerString={"Mit JSX"}/> : null}
              {count === 7 ? <Slide7/> : null}
              {count === 8 ? <Slide8/> : null}
              {count === 9 ? <FrageSlide UpperString={"Für was nutzt man JSX?"} LowerString={"Für Komponenten"}/> : null}
              {count === 10 ? <Slide10/> : null}
              {count === 11 ? <Slide11/> : null}
              {count === 12 ? <BeispielSlide String={"Beispiel"}/> : null}
              {count === 13 ? <FrageSlide UpperString={"Was ist wenn ich Zustände von meiner Komponente ändern will?"} LowerString={"Mit Hooks"}/> : null}
              {count === 14 ? <Slide14/> : null}
              {count === 15 ? <BeispielSlide String={"Beispiel mit useState und useEffect"}/> : null}
              {count === 16 ? <FrageSlide UpperString={"Wie funktioniert der Datenfluss in React?"} LowerString={""}/> : null}
              {count === 17 ? <Slide17/> : null}
              {count === 18 ? <BeispielSlide String={"Beispiel mit useContext"}/> : null}
              {count === 19 ? <FrageSlide UpperString={"Gibt es noch was anderes als Komponenten und Hooks?"} LowerString={"Built-In API's"}/> : null}
              {count === 20 ? <Slide20/> : null}
              {count === 21 ? <BeispielSlide String={"Beispiel mit lazy"}/> : null}
              {count === 22 ? <Slide22/> : null}
              {count === 23 ? <Slide23/> : null}
          </div>
          <footer class={"App-footer"}>
              {count === minLength ? null :  <p onClick={decCount} class={"Clicker"}>{"<"}&nbsp;</p>}
              <p>{count} / {maxLength}</p>
              {count === maxLength ? null :  <p onClick={incCount} class={"Clicker"}>&nbsp;{">"}</p>}
          </footer>
      </div>
  );
}

export default App;
