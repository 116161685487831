import "./slide14.css";

const Slide14 = () => {

    return (
        <div class={"animate__animated animate__fadeInDown Outer-Container"}>
        <div>
          <h1>Hooks</h1>
          <div class={"Inner-Container10"}>
            <h2 class={"Hooks-Text"}>Was sind Hooks?</h2>
            <h4 class={"State-Text"} style={{marginTop: "10px"}}>Hooks sind Funktionen, die React-Komponenten erweitern</h4>
            <div>
                <h2><u>useState</u></h2>
                <h4 class={"State-Text"}>Zustände von Variablen in einer Komponente setzen und speichern</h4>
            </div>
              <div>
                  <h2><u>useEffect</u></h2>
                  <h4 class={"State-Text"}>Wird aufgerufen basierend auf der gegebenen Bedingung</h4>
              </div>
          </div>
        </div>
      </div>
    );
}

export default Slide14;