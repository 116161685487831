import './slide4.css';

const Slide4 = () => {


    return (
        <div class={"animate__animated animate__fadeInDown"}>
            <h1 class={"header"}>Wie erstelle ich eine React-App?</h1>
            <div class={"Outer-Code-Container"}>
                <div class={"Code-container"}>
                    <code class={"Code-Header"}>Terminal</code>
                    <code>npx create-react-app 'name'</code>
                </div>

            </div>
            <div style={{marginTop: "30px"}}>
                <a href={"https://nodejs.org/en"} className={"Quellen-Links"} target={"_blank"}>Node.js</a>
            </div>
        </div>
    )

}

export default Slide4;