import React from "react";
import "./slide8.css";

const Slide8 = () => {

    return (
        <div class={"animate__animated animate__fadeInDown Outer-Container"}>
            <h1>Wie sieht JSX aus?</h1>
            <div class={"Code-Container-Up"}>
                <code>{"const MyElement = () => {"}</code>
                <code>&nbsp;{"return ("}</code>
                <code>&nbsp;&nbsp;{"<div>"}</code>
                <code>&nbsp;&nbsp;&nbsp;{"<h1>Hello, React!</h1>"}</code>
                <code>&nbsp;&nbsp;&nbsp;{"<p>This is a simple Element.</p>"}</code>
                <code>&nbsp;&nbsp;{"</div>"}</code>
                <code>&nbsp;{");"}</code>
                <code>{"};"}</code>
            </div>
            <div class={"Code-Container-Down"}>
                <code>{"const MyElement = () => {"}</code>
                <code>&nbsp;{"return React.createElement('div', null,"}</code>
                <code>&nbsp;&nbsp;{"React.createElement('h1', null, 'Hello, React!'),"}</code>
                <code>&nbsp;&nbsp;{"React.createElement('p', null, 'This is a simple Element.')"}</code>
                <code>&nbsp;{");"}</code>
                <code>{"};"}</code>
            </div>
        </div>
    )

}

export default Slide8;