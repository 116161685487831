import logo from "../../logo.svg";
import 'animate.css';
import "./slide0.css"

const Slide0 = () => {
  return(
      <div class={"animate__animated animate__zoomIn App-header-inner"}>
          <div>
              <img src={logo} alt="logo" class="App-logo"/>
          </div>
          <h1 class={"App-header-text"}>REACT</h1>
      </div>
  );
}

export default Slide0;