import "./beispielslide.css"

const BeispielSlide = ({String}) => {

    return (
        <div class={"animate__animated animate__fadeInDown Outer-Container Beispiel-Container"}>
            <h1>{String}</h1>
        </div>
        )
}

export default BeispielSlide;