import "./slide20.css"

const Slide20 = () => {

    return (
        <div class={"animate__animated animate__fadeInDown Outer-Container"}>
            <div>
                <h1>Built-In API's</h1>
                <div class={"Inner-Container10"}>
                    <h2 class={"Hooks-Text"}>Was sind Built-In API's?</h2>
                    <ul>
                        <li>Damit Komponenten effizienter definieren und besser steuern</li>
                    </ul>
                    <div>
                        <ul>
                            <li ><code>createContext</code>, nutzt man in Kombination zu useContext</li>
                            <li ><code>lazy</code>, rendert erst die Komponente wenn alle ihre Elemente geladen sind</li>
                            <li ><code>memo</code>, stoppt re-renders mit gleichen Daten über useMemo (sozusagen Cache)</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Slide20;
