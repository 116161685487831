import logo from "../../logo.svg";
import "./slide2.css";
import BulletPoint from "../bulltPoints/bulletPoint.componnent";
import "../slide3/slide3.css"
import React, {useEffect, useState} from "react";

const Slide2 = () => {

    const [screenSize, setScreenSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    // Funktion, die die Bildschirmgröße aktualisiert
    const updateScreenSize = () => {
        setScreenSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };

    useEffect(() => {
        // Event Listener hinzufügen, um die Bildschirmgröße zu aktualisieren
        window.addEventListener('resize', updateScreenSize);

        // Aufräumarbeiten: Event Listener entfernen, um Memory Leaks zu vermeiden
        return () => {
            window.removeEventListener('resize', updateScreenSize);
        };
    }, []);


    if (screenSize.width > 1400) {
        return (
            <>

                    <div className={"animate__animated animate__fadeInDown"}>
                        <div class={ "Container"}>
                            <h1>Was ist React?</h1>
                            <div class={"Outer-Component-Img"}>
                                <img src={logo} class={"Logo-Stil"} alt="logo"/>
                            </div>
                            <div className={"React-Logo"}>&nbsp;</div>
                        </div>
                        <div className={"AllBulletPointContainer Extra"}>
                            <div className={"BulletPoint-Container1"}>
                                <div style={{margin: "0 -90px"}}>
                                    <BulletPoint TextString={"JavaScript-Bibliothek"} PointPos={3}/>
                                </div>
                                <div style={{margin: "23% 0"}}>
                                    <BulletPoint TextString={"Nutzt hierarschich Komponenten"} PointPos={3}/>
                                </div>
                                <div style={{margin: "0 -90px"}}>
                                    <BulletPoint TextString={"Front-End Webenewicklung"} PointPos={3}/>
                                </div>
                            </div>
                            <div style={{margin: "200px"}}></div>
                            <div className={"BulletPoint-Container2"}>
                                <div style={{margin: "0 -90px"}}>
                                    <BulletPoint TextString={"Viele Erweiterungen"} PointPos={1}/>
                                </div>
                                <div style={{margin: "23% 0"}}>
                                    <BulletPoint TextString={"Mit JSX"} PointPos={1}/>
                                </div>
                                <div style={{margin: "0 -90px"}}>
                                    <BulletPoint TextString={"Plattformübergreifend"} PointPos={1}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    </>
                    );
                } else {
return (
                <div>
                    <h1 className={"HistoryHeader"}>Was ist React?</h1>
                    <div className={"Most-Outer-BulletPoint-Container HistoryContainer"}>
                        <div className={" animate__animated animate__fadeInDown Outer-BulletPoint-Container"}>


                            <BulletPoint TextString={"JavaScript-Bibliothek"} PointPos={3}/>


                            <BulletPoint TextString={"Nutzt hierarschich Komponenten"} PointPos={3}/>

                            <BulletPoint TextString={"Front-End Webenewicklung"} PointPos={3}/>


                            <BulletPoint TextString={"Viele Erweiterungen"} PointPos={3}/>

                            <BulletPoint TextString={"Mit JSX"} PointPos={3}/>

                            <BulletPoint TextString={"Plattformübergreifend"} PointPos={3}/>


                        </div>
                        <div className={"Big-Line"}>&nbsp;</div>
                    </div>
                </div>
);}

                }

                export default Slide2;