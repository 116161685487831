import logo from "../../logo.svg";
import "./slide23.css"


const Slide23 = () => {

    return (
        <div class={"animate__animated animate__zoomIn Last-Outer-Component"}>
            <h1>Vielen Dank für's Zuhören</h1>
            <div class={"Last-Outer-Component-Img"}>
                <img src={logo} alt="logo" class="App-logo"/>
            </div>
            <h1>Fragen?</h1>
        </div>
    )

}

export default Slide23;