const Slide11 = () => {

    return (
        <div class={"animate__animated animate__fadeInDown Outer-Container"}>
            <h1>Komponenten</h1>
            <div class={"Inner-Container10"}>
                <h2>Eingebaute Komponenten</h2>
                <ul>
                    <li><code>{"<Fragment/>"}</code> (<code>{"<>…</>"}</code>), gruppieren von mehreren Elemente</li>
                    <li><code>{"<Suspense/>"}</code> , Ausweichmöglichkeit falls die Komponente lädt</li>
                    <li><code>{"<StrictMode/>"}</code> , für das debuggen in der Entwicklungsphase</li>
                </ul>
            </div>
        </div>
    )

}

export default Slide11;