import "./slide22.css"

const Slide22 = () => {

    return (
        <div class={"animate__animated animate__fadeInDown Outer-Container"}>
            <h1>Quellen</h1>
            <div class={"Inner-Container10"}>
                <a href={"https://react.dev"} class={"Quellen-Links"} target={"_blank"}>https://react.dev</a>
                <a href={"https://de.wikipedia.org/wiki/React"} class={"Quellen-Links"} target={"_blank"}>https://de.wikipedia.org/wiki/React</a>
                <a href={"https://de.wikipedia.org/wiki/JSX_(JavaScript)"} class={"Quellen-Links"} target={"_blank"}>https://de.wikipedia.org/wiki/JSX_(JavaScript)</a>
                <a href={"https://www.youtube.com/watch?v=Tn6-PIqc4UM"} class={"Quellen-Links"} target={"_blank"}>https://www.youtube.com/watch?v=Tn6-PIqc4UM</a>
            </div>
        </div>
    )

}

export default Slide22;